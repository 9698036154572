/* -------------------------------- 

Primary style

-------------------------------- */
@import url("swiper.css");
.masonry-layout {
  column-count: 5;
  column-gap: 0;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}
.masonry-layout__panel {
  break-inside: avoid;
  padding: 5px;
}
.masonry-layout__panel-content {
  padding: 5px;
}
.videowrapp {
  border: 2px solid #cdc9c6;
  padding-top: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video h2 {
  padding: 15px;
  font-family: "Poppins", sans-serif;
}
.video {
  width: 1600px;
  max-width: 70vw;
  max-height: 80vh;
  margin: 0 auto;
  aspect-ratio: 16 / 8;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
}
.h1wrap {
  max-width: 1800px;
  padding-left: 10px;
  padding-right: 10px;

  margin: 0 auto;
}
.grid-wrapper {
  padding-left: 10px;
  padding-right: 10px;
  max-width: 1800px;
  columns: 30rem;
  margin: 0 auto;

  counter-reset: grid;
}
.grid-wrapper div {
  padding: 10px;
}
.grid-wrapper div:hover p {
  background-color: white;
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) !important;
  color: black;
}
.grid-wrapper div p {
  position: absolute;
  transform: translateY(calc(-100% - 10px)) translateX(10px);
  color: white;
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) !important;
  border: 1px solid white;
  width: max-content;
  border-radius: 0 0 15px 15px;
  border-radius: 50vw;
  padding-left: 10px;
  font-size: 15px;

  font-family: "Poppins", sans-serif;
  padding: 5px 15px 5px 15px;
}
.masonry-layout__panel-content img {
  width: 100%;
}

body.loaded::before {
  transform: translateY(100%);

  transition: all 0.8s cubic-bezier(0.7, 0, 0.3, 1) !important;
}

body.loaded::after {
  transform: translateY(100%);

  transition: all 0.9s cubic-bezier(0.7, 0, 0.3, 1) !important;
}

.smallerIMG .contentImg.zoomImg {
  scale: 0.6;
}

.smallerIMG .contentItem {
  aspect-ratio: 1;
}
.smallerIMG .contentItem {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-top: 15;
}

.smallerIMG .row .contentItem:nth-of-type(odd) {
  padding-top: 2px;
}

body::before,
body::after {
  display: block;
  width: 100vw;
  content: "";
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgb(156, 155, 155);
  z-index: 999999 !important;
  background: #e7e7e7;
  transform: translateY(0%);
  transition: all 0.8s cubic-bezier(0.7, 0, 0.3, 1);
}

body::after {
  z-index: 999998 !important;
  background-color: rgb(190, 190, 190);
  background-color: rgba(111, 67, 24, 0.29);
  transition: all 0.7s cubic-bezier(0.7, 0, 0.3, 1) !important;
}

a.submenu {
  display: inline-block;
  padding-right: 30px;
  font-style: 16px;
  color: black;
  font-weight: 300;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a.submenu:hover {
  color: #909090;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a.submenu.active {
  font-weight: 600;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.gallery-holder {
  border: 1px solid red;
}

.linkEmail {
  font-family: "Poppins", sans-serif;
  color: black;
  font-weight: 200;
  display: block;
  text-decoration: none;
  padding-top: 5px;
  padding-bottom: 10px;
}

.telefon {
  padding-bottom: 40px;
  font-family: "Poppins", sans-serif;
  font-size: 24px !important;
  font-weight: 200;
}
body {
  margin: 0;
  padding: 0;
}

.homepageSlider.textPage {
  height: auto;
  min-height: 70vh;
}
.homepageSlider.realizace {
  min-height: 150px;
}
.downloadHolder {
  padding-top: 40px;
}

.pageTextHolder.katalogy {
  width: 1200px;
}

.pageTextHolder.galerie.katalogy {
  width: 100%;
}

.pageTextHolder.katalogy.galerie {
  /*width: calc(1200px + 320px);*/
  position: relative !important;
}

.pageTextHolder.katalogy .inner {
  top: 50px;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  position: relative;
}

* {
  background-repeat: no-repeat !important;
}

.contentItem.halfWidth.noBg {
  background-color: rgba(255, 255, 255, 0);
}

.ImgPadTop {
  margin-top: 25px;
}

.opening.hot {
  position: absolute;
  margin-left: 505px;
  margin-top: -85px;
  line-height: 35px;
  font-family: "Poppins", sans-serif;
  font-size: 28px !important;
  font-weight: 200;
}

.opening.hot a {
  color: black;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1px solid black;
}

.opening.hot a:hover {
  color: #454545;
}

#map {
  width: 100vw;
  height: 65vh;
}

.innerShowroom1 {
  height: 100%;
  position: relative;
  padding-left: 320px;
  width: 50vw;
}

.innerShowroom2 {
  z-index: 9999;
  position: absolute;
  top: 50%;
  padding-right: 60px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: white;
}

.innerShowroom2 > * {
  color: white !important;
}

.opening {
  display: block;
  padding-top: 10px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}

.container {
  position: relative;
}

.cleared {
  clear: both;
}

.col33 {
  width: 33%;
  float: left;
}

.row {
  padding-top: 50px;
}

.pageTextHolder {
  position: relative;
  width: calc(50vw - 0px);
  height: 100%;
  padding-right: 30px;
  padding-left: 320px;
}

.pageTextHolder .inner {
  padding-top: 170px;
  padding-bottom: 80px;
}

@media (min-height: 900px) and (max-height: 5000px) {
  .pageTextHolder .inner {
    z-index: 9999;
    position: absolute;
    top: 50%;
    padding-right: 60px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .pageTextHolder.galerie .inner {
    position: relative;
  }
  .pageTextHolder {
    position: absolute;
  }
  .showroom {
    height: 60vh;
  }
  .imgShowroom {
    margin-top: -119vh;
    height: 119vh;
  }
}

.imgShowroom {
  background-image: url(../img/kontakt.jpg);
  width: 50vw;
  background-size: cover;
  right: 0px;
  float: right;
}

@media (min-height: 1px) and (max-height: 899px) {
  .showroom {
    height: 700px;
  }
  .imgShowroom {
    margin-top: -1200px;
    height: 1200px;
  }
  #map {
    height: 100vh;
  }
}

.showroom {
  background-color: black;
  width: 100vw;
  display: block;
}

.zoomImg {
  overflow: hidden;
}
.contentImg.zoomImg::before {
  display: block;
  position: absolute;
  margin-top: 40px;
  padding-left: 30px;
  color: white;
  font-size: 30px;
  content: "\f06e";
  margin-left: 10px;
  font-family: "Font Awesome 5 Free";
  opacity: 0.2;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.docsLink {
  display: block;
  text-decoration: none;
  color: black;
  font-size: 17px;
  font-weight: 200;
  padding-top: 5px;
  padding-bottom: 5px;
}

.docsLink:hover {
  text-decoration: underline;
}

.docsLink:before {
  font-size: 20px;
  content: "\f15b";
  margin-right: 15px;
  margin-left: 10px;
  font-family: "Font Awesome 5 Free";
}

.docsLink span {
  padding-left: 30px;
  font-size: 14px;
}

.contentImg.zoomImg:hover img {
  -webkit-box-shadow: 0px 0px 41px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 41px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 41px -10px rgba(0, 0, 0, 0.75);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.contentImg.zoomImg img {
  -webkit-box-shadow: 0px 0px 0px -0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 0px -0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 0px -0px rgba(0, 0, 0, 0.75);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.contentImg.zoomImg:hover::before {
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.contentItem.fullWidth {
  padding-bottom: 40px;
}

.contentItem.oneRow {
  width: calc(100% - 15px);
  background-color: black;
}

.contentItem.oneRow .innerC {
  width: calc(50% + 10px);
  float: left;
}

.contentItem.oneRow .innerC {
  background-color: black;
}

.contentItem.oneRow .innerC > * {
  color: white !important;
}

.contentItem.oneRow .contentImg {
  width: calc(50% - 10px);
  overflow: hidden;
  float: left;
}

.contentItem.oneRow .contentImg img {
  width: 105%;
}

.contentItem .innerC {
  padding: 40px;
}

.bgWhite {
  background-color: white !important;
}

/*
.contentItem.halfWidth:nth-of-type(1) {
width: calc(60% - 15px);
}*/

.contentItem.halfWidth {
  width: calc(50% - 15px);
  margin-right: 15px;
  float: left;
  background-color: #eeeeee;
}

.section {
  position: relative;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: black;
  font-size: 14px;
  padding-bottom: 30px;
}

.subheadline {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  padding-top: 30px;
  padding-bottom: 20px;
}

span.city {
  display: block;
  padding-top: 5px;
  font-weight: 100;
  font-size: 25px;
  line-height: 26px;
}

.contentImg img {
  width: 100%;
}

.columns {
  padding-top: 20px;
  column-count: 3;
}

.columns.one {
  column-count: 1;
}

.linkContent {
  display: block;
  padding-top: 15px;
  padding-bottom: 25px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
}

.linkContent::after {
  display: inline-block;
  content: "\f35d";
  margin-left: 10px;
  font-family: "Font Awesome 5 Free";
}

p.perexHome {
  font-family: "Poppins", sans-serif;
  line-height: 26px;
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 14px;
}

.downloadBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 2rem;
  padding-left: 0;
}

.downloadBox a {
  flex: 1;
  margin-top: 1rem;
}

p.perexHome strong {
  font-weight: 600;
  font-size: 14px;
}

p.perexHome.small {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  padding-bottom: 20px;
  color: #d0d0d0;
  width: 80%;
  padding-right: 40px;
}

p.perexHome.small.black {
  color: black;
}

p.perexHome.small.black strong {
  color: black;
}

p.perexHome.small strong {
  font-weight: 600;
  color: white;
  display: inline-block;
  margin-bottom: 5px;
}

p.perexHome.padded {
  padding-top: 15px;
  padding-bottom: 15px;
}

p.perexHome.padded.width80 {
  width: 80%;
}

h1 {
  font-size: 80px;
  font-weight: 900;
  margin: 0px;
  line-height: 80px;
  font-family: "Poppins", sans-serif;
}

h2 {
  font-size: 40px;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
}

* {
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #d7d7d7;
  background: radial-gradient(
        60.21% 60.21% at 53.13% 49.13%,
        rgba(111, 67, 24, 0.29) 0%,
        rgba(0, 0, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #d7d7d7;
}

.header {
  z-index: 99999 !important;
  position: fixed;
  width: 100vw;
  height: 180px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header.scroll {
  background-color: white;
  height: 80px;
  position: fixed;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header.scroll .logoPorte {
  width: 80px;
  top: 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header.scroll div.menu {
  top: 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.logoPorte {
  position: absolute;
  width: 150px;
  left: 50px;
  top: 50px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

div.menu {
  display: block;
  width: auto;
  position: absolute;
  left: 320px;
  top: 80px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

div.menu > div {
  display: inline-block;
  float: left;
  padding-right: 50px;
}

div.menu > div a {
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  color: black;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

div.menu > div a:hover {
  color: #767676;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.homepageSlider {
  width: 100vw;
  z-index: 100 !important;
  min-height: 100vh;
}

.homepageSlider .imgHolder {
  height: 110vh;
  width: calc(100vw - 650px);
  position: absolute;
  right: 0px;
  top: -10%;
  overflow: hidden;
}

.homepageSlider .imgHolder img,
.homepageSlider .imgHolder.page img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  max-height: 110vh;
}

.homepageSlider .imgHolder.page {
  height: 120vh;
  width: calc(50vw);
  position: absolute;
  right: 0px;
  top: -10%;
  overflow: hidden;
}

.sliderMenu {
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 320px;
  min-width: auto;
  width: calc(100vw - 320px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sliderMenu .slideItem {
  display: block;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sliderMenu .slideItem:hover .slideText {
  margin-left: -50px;
  -webkit-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 0.3s ease;
}

.sliderMenu .slideItem:hover .slideSubmenu {
  display: block;
}

.singleSwipe::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background: linear-gradient(90deg, #cdc9c6 0%, rgba(217, 217, 217, 0) 23.49%);
}

.sliderMenu .slideText {
  position: absolute;
  height: 100px;
  z-index: 999;
  font-size: 38px;
  cursor: pointer;
  font-weight: 900;
  line-height: 100px;
  font-family: "Poppins", sans-serif;
  -webkit-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
}

.sliderMenu .slideSubmenu {
  background-color: white;
  height: 100px;
  z-index: 998;
  display: none;
  position: absolute;
  line-height: 100px;
  padding-right: 50px;
  -webkit-box-shadow: 0px 2px 30px -2px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 2px 30px -2px rgba(0, 0, 0, 0.39);
  box-shadow: 0px 2px 30px -2px rgba(0, 0, 0, 0.39);
}

.sliderMenu .slideSubmenu .line {
  height: 50px;
  display: inline-block;
  border-bottom: 1px solid black;
  width: 50px;
}

.sliderMenu .slideSubmenu.sub01 {
  padding-left: 340px;
}

.sliderMenu .slideSubmenu.sub02 {
  padding-left: 400px;
}

.sliderMenu .slideSubmenu.sub03 {
  padding-left: 250px;
}

.sliderMenu .slideSubmenu.sub04 {
  padding-left: 300px;
}

.sliderMenu .slideSubmenu.sub05 {
  padding-left: 360px;
}

.sliderMenu .slideSubmenu a {
  text-decoration: none;
  padding-left: 50px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: black;
  font-size: 14px;
  font-weight: 600;
}

.sliderMenu .slideSubmenu a:hover {
  color: #767676;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.homepageSlider .imgHolder.slider {
  background-size: cover;
  background-position: center center;
}

.homepageSlider .imgHolder.page {
  background-size: cover;
}
/* 
.homepageSlider .imgHolder.page.pivato {
  background-position: right;
  background-image: url(../img/pivatoHome.jpg);
}

.homepageSlider .imgHolder.page.about2 {
  background-image: url(../img/abouthome.jpg);
}

.homepageSlider .imgHolder.page.enrico {
  background-position: center;
  background-image: url(../img/enricohome.jpg);
}

.homepageSlider .imgHolder.page.eclisse {
  background-position: center;
  background-image: url(../img/eclissehome.jpg);
}

.homepageSlider .imgHolder.page.jj {
  background-position: center top;
  background-image: url(../img/jjhone.jpg);
}

.homepageSlider .imgHolder.page.okey {
  background-image: url(../img/okeyhome.jpg);
}

.homepageSlider .imgHolder.page.legno {
  background-image: url(../img/legnoHome.jpg);
}

.homepageSlider .imgHolder.page.biossun {
  background-image: url(../img/biossunHome.jpg);
} */

.homepageSlider .imgHolder.slider.default {
  background-image: url(../img/slideDef.jpg);
}

.homepageSlider .imgHolder.slider.default.slide01 {
  background-image: url(../img/slide02.jpg);
}

.homepageSlider .imgHolder.slider.default.slide02 {
  background-image: url(../img/slide04.jpg);
}

.homepageSlider .imgHolder.slider.default.slide03 {
  background-image: url(../img/slide03.jpg);
}

.homepageSlider .imgHolder.slider.default.slide04 {
  background-image: url(../img/slide01.jpg);
}

.homepageSlider .imgHolder.slider.default.slide05 {
  background-image: url(../img/slide05.jpg);
}

.preload01,
.preload02,
.preload03,
.preload04 {
  display: block;
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute;
  left: -50px;
  top: -50px;
}

.preload01 {
  background-image: url(../img/slide02.jpg);
}

.preload02 {
  background-image: url(../img/slide04.jpg);
}

.preload03 {
  background-image: url(../img/slide03.jpg);
}

.preload04 {
  background-image: url(../img/slide01.jpg);
}

.about {
  margin-top: 150px;
  margin-bottom: 150px;
  position: relative;
  width: 100vw;
  height: 80vh;
}

.contentFullWidth {
  position: relative;
  width: 100vw;
  height: 85vh;
}

.contentFullWidth.biossun {
  height: 80vh;
}

.contentFullWidth.biossunFH {
  height: 100vh;
}

.contentFullWidth.biossun .right {
  position: absolute;
  height: 50%;
  width: 450px;
  left: 80%;
  margin-left: -400px;
  bottom: -50px;
}

.contentFullWidth.biossun.biossunFH .right {
  position: absolute;
  height: 55%;
  width: 290px;
  right: 50px;
  bottom: 20%;
}

.contentFullWidth.biossun.biossunR .right {
  position: absolute;
  height: 55%;
  width: 450px;
  left: 20%;
  margin-left: -50px;
  bottom: -50px;
}

.contentFullWidth.biossun.biossunFH .text {
  background-color: white;
  color: white;
  height: calc(100% - 0px);
  margin-top: 0px;
  width: 100%;
}

.contentFullWidth.biossun .right.black > *,
.contentFullWidth.biossun .right.black a {
  color: white !important;
}

.contentFullWidth.biossun .right.white .text {
  background-color: white;
}

.contentFullWidth.biossun .right.white .text > * {
  color: black;
}

.contentFullWidth.biossun .text {
  background-color: black;
  color: white;
  height: calc(100% - 0px);
  margin-top: 0px;
  width: 100%;
}

.contentFullWidth.biossun .left {
  float: left;
  width: 80%;
  height: 100%;
}

.biossun img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.contentFullWidth .left.biossun01 {
  background-size: cover;
  background-position: left center;
  background-repeat: none;
  background-image: url(../img/biossun01.jpg);
}

.contentFullWidth .left.biossun02 {
  background-size: cover;
  height: 100vh;
  width: 50%;
  background-repeat: none;
  margin-left: 150px;
  background-position: bottom;
  background-image: url(../img/biossun02.jpg);
}

.contentFullWidth .left.biossun03 {
  background-size: cover;
  background-position: left center;
  background-repeat: none;
  background-image: url(../img/biossun03.jpg);
  float: right;
}

.contentHolder {
  position: relative;
  width: 100vw;
  margin: 0 auto;
  margin-top: 150px;
}
.contentHolder.nopad {
  margin-top: 0;
}
.contentHolder .inner {
  width: 100%;
  padding-left: 15px;
  padding-bottom: 15px;
  position: relative;
  margin: 0 auto;
  width: calc(100vw - 2 * 265px);
}

.about .left,
.about .right {
  float: left;
  width: 50%;
  height: 100%;
}

.contentFullWidth .left {
  float: left;
  width: 60%;
  height: 100%;
}

.contentFullWidth .right {
  float: left;
  width: calc(40%);
  height: 100%;
}

.about .title {
  position: absolute;
  top: 130px;
  width: 40vw;
  left: 50%;
  margin-left: -150px;
  font-size: 40px;
  line-height: 50px;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
}

.about .text {
  background-color: white;
  height: calc(100% - 180px);
  margin-top: 180px;
  width: calc(50vw - 200px);
  min-width: 50vw;
}

.about .text .inner {
  height: 100%;
  position: relative;
}

.contentFullWidth .text {
  background-color: black;
  color: white;
  height: calc(100% - 0px);
  margin-top: 0px;

  width: calc(50vw - 350px);
}

.contentFullWidth .text .section {
  color: white;
}

.contentFullWidth .text .inner {
  height: 100%;
  position: relative;
}

.contentFullWidth .text .inner .inner2 {
  height: auto;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.about .text .inner .inner2 {
  height: auto;
  padding-left: 70px;
  padding-right: 70px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.about .text p {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  line-height: 30px;
  font-weight: 300;
}

.rene {
  padding-left: 40px;
  display: block;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: black;
  font-size: 14px;
  font-weight: 600;
}

.rene::before {
  position: absolute;
  display: block;
  content: "";
  width: 28px;
  margin-top: 6px;
  margin-left: -40px;
  height: 1px;
  background-color: black;
}

.contentFullWidth .left.dinamica {
  background-size: cover;
  background-position: left center;
  background-image: url(../img/Dinamica.jpg);
}

.moreAbout {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: black;
  font-size: 14px;
  display: inline-block;
  font-weight: 400;
  color: #000;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 17px;
  font-weight: 700;

  display: block;
}

.about .left {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../img/karpisek.jpg);
}

.footer {
  position: relative;
  width: 1050px;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: black;
  font-size: 14px;
  height: 70px;
}

.footer .left,
.footer .center,
.footer .right {
  width: 33%;
  float: left;
}

.footer .right {
  text-align: right;
}

.footer .center {
  text-align: center;
}

.footer .center .logo {
  width: 100px;
  margin-left: 130px;
  margin-top: -20px;
}

.download {
  position: relative;
  width: 1050px;
  margin: 0 auto;
  margin-top: 270px;
  margin-bottom: 270px;
  height: 120px;
  background-color: white;
}

.downloadText {
  font-size: 35px;
  font-weight: 700;
  line-height: 120px;
  margin-left: -50px;
  width: 50%;
  float: left;
  font-family: "Poppins", sans-serif;
}

.downloadImage {
  position: absolute;
  left: 50%;
  width: 405px;
  height: 401px;
  margin-left: -202px;
  margin-top: -120px;
  background-image: url(../img/katalog.png);
}

.downloadLink {
  text-align: right;
  height: 100%;
  float: right;
  padding-right: 50px;
  height: 120px;
  line-height: 120px;
  display: block;
  width: 50%;
  background-color: black;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: white;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none;
}

.downloadLink:hover {
  background-color: #767676;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.animTo.animUp.hidden {
  -webkit-transform: translateY(75%);
  -ms-transform: translateY(75%);
  transform: translateY(75%);
}
.animTo.animUp150.hidden {
  -webkit-transform: translateY(75px);
  -ms-transform: translateY(75px);
  transform: translateY(75px);
}

.animTo.animRight.hidden {
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}
.animTo.hidden {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: initial;
  opacity: 0;
}
.animTo.hidden.animNow {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: initial;
  opacity: 1;
}
.contentFullWidth.biossun.biossunFH .right {
  width: 400px;
}
@media (min-width: 320px) and (max-width: 1500px) {
  .header {
    height: 90px;
  }

  .logoPorte {
    width: 120px;
    top: 40px;
  }

  .pageTextHolder.katalogy {
    width: 100vw;
  }

  div.menu {
    top: 60px;
    left: 230px;
  }

  .sliderMenu .slideItem {
    height: 80px;
  }

  .sliderMenu {
    left: 230px;
  }

  .pageTextHolder {
    padding-left: 230px;
  }

  .contentHolder .inner {
    width: calc(100vw - 2 * 180px);
  }
}
@media (min-width: 1024px) and (max-height: 700px) {
  .about {
    height: 110vh;
  }

  .contentFullWidth {
    height: 150vh;
  }

  .contentFullWidth.biossun {
    height: 100vh;
  }

  .contentFullWidth.biossun .right {
    width: 610px;
  }

  .homepageSlider {
    height: 110vh;
  }

  .sliderMenu .slideItem {
    height: 70px;
  }
}

/* ------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------
------------------------------------- mobile ------------------------------------------
---------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------- */

@media (min-width: 320px) and (max-width: 900px) {
  div.menu {
    display: none;
  }

  .submenu {
    display: block;
  }
  .masonry-layout {
    column-count: 3;
    column-gap: 0;
  }
  .sliderMenu .slideItem {
    height: auto;
    margin-bottom: 20px;
  }

  .opening.hot {
    position: relative;
    margin-left: auto;
    margin-top: auto;
  }

  #map {
    height: 70vh;
  }

  .showroom {
    height: 350px;
  }

  .imgShowroom {
    width: 100%;
    display: none;
    height: 50vh;
    float: none;
    margin-top: auto;
  }
  .innerShowroom1 {
    width: 100%;
    padding-left: 25px;
    height: 350px;
  }

  h1 {
    font-size: 60px;
    line-height: 60px;
  }

  .download {
    width: 100vw;
  }

  .downloadImage {
    position: absolute;
    right: 00px;
    width: 200px;
    height: 250px;
    background-size: cover;
    margin-left: auto;
    margin-top: -120px;
    background-image: url(../img/katalog.png);
  }

  .about .title {
    position: relative;
    top: auto;
    left: auto;
    width: 100vw;
    padding-left: 25px;
    margin-left: 0px;
    font-size: 25px;
    line-height: 35px;
    padding-bottom: 35px;
    font-weight: 900;
    font-family: "Poppins", sans-serif;
  }

  .about .left {
    height: 50vh;
    width: 100vw;
  }

  .about .text {
    background-color: white;
    height: auto;
    margin-top: 180px;
    width: calc(100vw);
  }

  .footer {
    width: 100vw;
    padding-left: 25px;
    padding-right: 25px;
    height: 40px;
  }

  .about .text .inner .inner2 {
    width: 100%;
    padding-top: 45px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .footer .left,
  .footer .center,
  .footer .right {
    width: 45%;
    height: 30px;
    float: left;
  }

  .footer .center {
    display: none;
  }

  .footer .right {
    width: 45%;
    height: 30px;
    float: right;
  }

  .footer .center .logo {
    margin-left: 0px;
    width: 60px;
    display: none;
    height: 40px;
    text-align: center;
  }

  .about .title {
    position: relative;
  }

  .about .left,
  .about .right {
    width: 100%;
    float: none;
    height: auto !important;
  }

  .about .left {
    height: 50vh !important;
  }

  .about .text {
    margin-top: auto;
  }

  .about .text .inner .inner2 {
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }

  p.perexHome.small {
    width: 100%;
    padding-right: 0px;
  }

  .download {
    position: relative;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 80px;
    height: 180px;
    background-color: white;
  }

  .downloadText {
    font-size: 20px;
    font-weight: 700;
    line-height: 90px;
    margin-left: 20px;
    width: 100vw;
    float: none;
    position: relative;
    font-family: "Poppins", sans-serif;
  }

  .downloadLink {
    text-align: left;
    height: 100%;
    float: right;
    padding-left: 20px;
    height: 90px;
    line-height: 90px;
    width: 100vw;
  }

  .about {
    margin-top: 0px;
    padding-top: 40px;
    height: auto;
  }

  .sliderMenu .slideItem:hover .slideText {
    margin-left: 0px;
  }

  .sliderMenu .slideText {
    font-size: 20px;
    margin-left: 0px;
    line-height: 40px;
    height: auto;
    position: relative;
    background-color: white;
    padding-left: 20px;
    font-weight: 700;
    display: inline-block;
    width: auto;
    padding-right: 20px;
  }

  .sliderMenu .slideSubmenu.sub01,
  .sliderMenu .slideSubmenu.sub02,
  .sliderMenu .slideSubmenu.sub03,
  .sliderMenu .slideSubmenu.sub04,
  .sliderMenu .slideSubmenu.sub05 {
    padding-left: 0px;
  }

  .homepageSlider .imgHolder {
    margin-top: 0px;
  }

  .sliderMenu .slideItem {
    margin-bottom: 15px;
  }

  .sliderMenu .slideSubmenu .line {
    display: none;
  }

  .sliderMenu .slideSubmenu {
    height: 60px;
    padding-right: 0px;
    position: relative;
    height: auto;
    line-height: 30px;
    background-color: rgba(255, 255, 255, 0);
    -webkit-box-shadow: 0px 2px 30px -2px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 2px 30px -2px rgba(0, 0, 0, 0);
    box-shadow: 0px 2px 30px -2px rgba(0, 0, 0, 0);
  }

  .sliderMenu .slideSubmenu a {
    display: inline-block;
    background-color: black;
    width: auto;
    color: white;
    font-weight: 400;
    padding-left: 20px;
    padding-right: 20px;
  }

  .sliderMenu {
    left: 0px;
    padding-left: 15px;
    z-index: 9999;
    position: absolute;

    top: 30vh;
    width: calc(100vw - 15px);
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }

  .header {
    background-color: white;
    position: fixed;
    height: 80px;
  }

  .logoPorte {
    width: 80px;
    left: 20px;
    top: 20px;
  }

  .homepageSlider .imgHolder {
    width: 100vw;
    height: 100vh;
  }

  .pageTextHolder {
    padding-left: 25px;
  }

  .pageTextHolder {
    position: relative;
    width: 100vw;
    height: auto;
    padding-right: 30px;
    padding-left: 30px;
  }

  .homepageSlider .imgHolder.page {
    height: 80vh;
    width: calc(100vw);
    position: relative;
    right: 0px;
    top: 0;
  }

  .contentHolder .inner {
    padding-left: 0px;
    width: calc(100vw - 30px);
  }

  p.perexHome.padded.width80 {
    width: 100%;
  }

  .contentFullWidth {
    height: auto;
  }

  .contentFullWidth .left {
    float: none;
    margin: 0 auto;
    position: relative;
    width: calc(100vw - 30px);
    height: 60vh;
  }

  .contentFullWidth .right {
    float: none;
    position: relative;
    margin: 0 auto;
    width: calc(100vw - 30px);
    height: auto;
  }

  .contentFullWidth .text .inner {
    width: calc(100vw - 30px);
    padding-top: 40px;
    background-color: black;
  }

  .contentFullWidth .text .inner .inner2 {
    height: auto;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    top: 0;
    width: 100%;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }

  .col33 {
    width: 100%;
    float: left;
  }

  .contentItem.halfWidth {
    width: calc(100%);
  }

  .contentHolder {
    margin-top: 50px;
  }

  .contentFullWidth.biossun {
    height: auto;
    width: calc(100vw - 30px);
    margin: 0 auto;
    position: relative;
  }

  .contentFullWidth.biossun .left {
    float: none;
    height: 50vh;
    width: 100%;
  }
  .contentFullWidth.biossun .right,
  .contentFullWidth.biossun.biossunR .right,
  .contentFullWidth.biossun.biossunFH .right {
    width: 100%;
    right: auto;
    height: auto;
    position: relative;
    bottom: auto;
    margin-left: auto;
    left: auto;
  }

  h2 {
    font-size: 35px;
    line-height: 35px;
  }

  .contentFullWidth.biossun .text {
    padding-bottom: 30px;
  }

  .contentFullWidth .left.biossun02 {
    margin-left: auto;
  }

  .contentFullWidth .text .inner.bs {
    background-color: white !important;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .masonry-layout {
    column-count: 4;
    column-gap: 0;
  }
}

@media (min-width: 1600px) and (max-width: 50000px) {
  .masonry-layout {
    column-count: 7;
    column-gap: 0;
  }
}

@media (min-width: 320px) and (max-width: 500px) {
  .masonry-layout {
    column-count: 2;
    column-gap: 0;
  }

  a.submenu {
    display: block !important;
  }
}
